import { Component, EventEmitter, HostListener, Input, OnInit, Output, } from '@angular/core';
import { EcoCostService } from 'src/app/services/eco-cost/eco-cost.service';

@Component({
  selector: 'pro-product-dropdown',
  templateUrl: './product-dropdown.component.html',
  styleUrls: ['./product-dropdown.component.css']
})
export class ProductDropdownComponent implements OnInit {
  @Input() item;
  @Input() selected;
  @Input() itemCategory;
  @Output() chooseProd: EventEmitter<any> = new EventEmitter();
  @Output() clickSpecification: EventEmitter<any> = new EventEmitter();
  @Output() clickTrocarLoja: EventEmitter<any> = new EventEmitter();

  constructor(private ecoCostService: EcoCostService) { }

  products = [];
  productsFiltered = [];
  productsVisible = [];
  productSelected;
  complementar = "";
  lastSearch: string = "";

  isDropdownVisible = true;
  isFilterVisible = false;
  isComplementar = false;
  isDropdownDisabled = false;

  isVisible = true;

  enabled = true;

  productsIncrement = 3;
  filtroId={id:"",value:""}
  radios = [];

  isDesktop = false;
  searchBarCollapsed = true;

  isFinancialCost: boolean;

  @HostListener('window:resize', ['$event'])

  onResize() {
    this.isDesktop = window.innerWidth >= 768;
  }

  ngOnInit(): void {
    this.isDesktop = window.innerWidth >= 768;

    if (!this.enabled)
      this.isDropdownVisible = this.isFilterVisible = false;

    this.loadProducts();
    this.setUnityToLowerCase();
    this.ecoCostService.isFinancialCost$.subscribe((isFinancialCost) => this.isFinancialCost = isFinancialCost)
  }

  setUnityToLowerCase() {
    this.itemCategory.unityMeasurement = this.itemCategory.unityMeasurement.toLowerCase();
    if (this.itemCategory.unityMeasurement === "m2")
      this.itemCategory.unityMeasurement = "m" + "\u00B2";
  }

  changeRadio(i, item) {

    let currentState = this.radios[i];
    for(let j = 0; j < this.radios.length; j++){
      this.radios[j] = false;
    }

    if (!currentState) {
      this.radios[i] = true;
    }

    this.filtro(i, item);
  }

  // função de aplicar filtro
  filtro(i, item) {
    let combinationId = item.combinations.combinationQuestionAnswers.find(f => f.answerId == item.questions[0].answers[i].id).combinationId;
    let productCategorySpecificationId = item.combinations.specifications.find(f => f.combinationId == combinationId).productCategorySpecificationId;
    let name = item.combinations.specifications.find(f => f.combinationId == combinationId).valueFilter

    if (this.filtroId.id == "" || this.filtroId.value != name || this.radios[i] ){
      this.filtroId = { id: productCategorySpecificationId, value: name }

    } else {
      this.filtroId = { id: "", value: "" }
    }
    this.refreshProducts();
  }

  // atualiza lista de produtos
  refreshProducts() {
    this.itemCategory.packageProdutcs.forEach(s =>
      s.appearFilt = this.isShown(s))

    let aux = this.productsVisible.length
    this.productsVisible = []
    this.products = []

    this.itemCategory.packageProdutcs.forEach(s=>{
      if (s.appearFilt) {
        this.products.push(s)
      }
    })
    this.onSearch(this.lastSearch);
  }
  // função de verifiicação se entidade é mostrado ou não
  isShown(itemCat): boolean {
    if (this.filtroId.id == "")
      return true
    if (itemCat.products.productSpecifics.find(s => s.productCategorySpecificationId == this.filtroId.id).value == this.filtroId.value)
      return true
    return false
  }


  loadProducts() {
    this.products = this.itemCategory.packageProdutcs;

    if (!this.products.length) {
      this.enabled = false
    }

      this.selected.forEach((selected) => {
        if (this.itemCategory.id == selected.productCategoryId) {
          this.itemCategory.packageProdutcs.forEach((element) => {
            if (element.id == selected.packagedProduct.id) {
              this.productSelected = selected;
          }else if(this.itemCategory.hasFatherSelected){
            this.productSelected = this.itemCategory;
            this.productSelected.packagedProduct = selected.packagedProduct;
          }
        })
      }
    })

    if (this.itemCategory.isComplementary) {
      this.isComplementar = true;

      for (let j = 0; j< this.item.productCategories[0].packageProdutcs.length; j++) {
          if (this.item.productCategories[0].packageProdutcs[j].productId == this.itemCategory.fatherProductApplicationId) {
            this.complementar = this.item.name
          }
      }
    } else {
      this.isComplementar = false;
    }

      this.setItemSelectedAsFirst();
      this.productsFiltered = JSON.parse(JSON.stringify(this.products));  // deep copy
      this.increaseProductsVisible();
  }

  setItemSelectedAsFirst(){
    let indexProductSelected;

    for(let i = 0; i < this.products.length; i++){
      // verifica se o id da lista de produtos é igual ao id do produto selecionado
      if(this.products[i].id === this.productSelected.packagedProduct.id){
        indexProductSelected = i;
        break;
      }
    }
    // Se o índice já estiver na posição 0, não há necessidade de mover
    if (indexProductSelected === 0) return;

    // Item a ser movido
    const itemToMove = this.products[indexProductSelected];

    // Usa slice para combinar o item selecionado no início com o resto dos itens
    this.products = [itemToMove, ...this.products.slice(0, indexProductSelected), ...this.products.slice(indexProductSelected + 1)];

  }

  isMoreToLoad() {
    return this.productsVisible.length > 0 && this.productsVisible.length < this.productsFiltered.length;
  }

  increaseProductsVisible() {
    for (let i = this.productsVisible.length, j = 0; j < this.productsIncrement && i < this.productsFiltered.length; i++, j++)
      this.productsVisible.push(this.productsFiltered[i]);
  }

  changeDropdown() {
    this.isDropdownVisible = !this.isDropdownVisible;
  }

  changeFilter() {
    this.isFilterVisible = !this.isFilterVisible;
    console.log(this.radios);

  }

  changeEnabled() {
    this.enabled = !this.enabled;
  }

  changeSearchBar() {
    this.searchBarCollapsed = !this.searchBarCollapsed;
  }

  isSelected(prodID) {
    if (this.productSelected) {
      return prodID == this.productSelected.packagedProduct.id
    }
  }

  nameStore(name: string){
    if(name.length > 20){
      return `${name.slice(0, 20)}...`;
    }
    return name;
  }

  onChooseProductHandler(prod) {
    let prodArray = [this.item.interventionId, this.itemCategory, prod, this.item.id, true, this.item, this.selected]
    this.chooseProd.emit(prodArray);
  }

  calcularVlr(qnt, vlrMin) {
    var und = parseFloat(qnt + "".replace(',', '.'));
    var vlr = parseFloat(vlrMin);
    var calculo = (vlr / und).toFixed(2);
    return calculo.replace('.', ',');
  }

  onOpenSpecificationHandler(cat, prod) {
    var arrayCatProd = [cat, prod]
    this.clickSpecification.emit(arrayCatProd);
  }

  onClickTrocarLojaHandler(prod, cat, intervId, id) {
    let arrayTrocarLoja = [prod, cat, intervId, id]
    this.clickTrocarLoja.emit(arrayTrocarLoja);
  }

  onSearch(value: string) {
    this.lastSearch = value;
    this.productsFiltered = JSON.parse(JSON.stringify(this.products.filter(prod => prod?.products?.name?.toLowerCase().includes(value))));  // parse e stringify para deep copy sem referenciar o array original
    this.productsVisible = [];
    this.increaseProductsVisible();
  }
}
