import { animate, style, transition, trigger } from '@angular/animations';
import { NgIf, NgStyle } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'pro-lottie-animation',
  standalone: true,
  imports: [LottieComponent, NgStyle, NgIf],
  templateUrl: './lottie-animation.component.html',
  styleUrl: './lottie-animation.component.css',
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('900ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('700ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})


export class LottieAnimationComponent implements OnInit {

  @Input() width = 250;
  @Input() height = 250;

  //caso não seja passado input, essas frases serão as padrões
  @Input() text: string [] = [
    "Aguarde um pouquinho... Estamos lendo os manuais para garantir combinações perfeitas!",
    "Checando a compatibilidade entre os produtos para que tudo funcione perfeitamente. Só mais um segundo!",
    "Quase lá! Estamos checando a compatibilidade técnica das soluções para evitar problemas para sua reforma.",
    "Segura aí! Estamos verificando se os produtos são compatíveis com o que você precisa.",
    "Calculando para evitar  desperdícios e falta de materiais. Já já terminamos.",
    "Estamos ajustando os cálculos para evitar qualquer sobra ou falta. Falta pouco!",
    "De olho nos manuais e nas melhores opções para você. Já estamos quase prontos!",
    "Espere um pouco! Calculando mais de 2 bilhões de opções possíveis para sua reforma!",
    "Carregando... Prometemos que será incrível!",
    "Quase lá! Só mais um pouquinho de paciência.",
    "Falta pouco! Só checando os detalhes finais.",
    "Passamos em várias lojas e estamos trazendo as melhores opções!",
    "Estamos nas lojas virtuais selecionando os produtos mais bacanas! Aguenta aí!",
  ];

  textShowing: string = '';

  private animationItem: AnimationItem | undefined;
  private intervalId: any;
  private i: number = 0;
  previousTextShowing: string = '';

  options: AnimationOptions = {
    path: '/assets/animations/loading-interv.json',
    loop: true,
    autoplay: true
  };

  ngOnInit(): void {
    if (this.text.length > 0) {
      this.textShowing = this.text[0]; // Inicia com o primeiro na lista
    }
    this.startTextRotation(); // Começa a rodar os textos
  }

  ngOnDestroy() {
    this.stopTextRotation();
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  startTextRotation() {
    this.intervalId = setInterval(() => {
      this.changeTextShowing(this.text);
    }, 8000);
  }

  stopTextRotation() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  changeTextShowing(text: string[]) {
    this.textShowing = ''
    setTimeout(() => {
      this.textShowing = text[this.i];
      this.i = (this.i + 1) % text.length; // Atualiza o índice
    }, 700);

    if (this.i >= text.length - 1) {
      this.i = 0;
    } else {
      this.i++;
    }
  }

  //métodos da animação
  play(): void {
    if (this.animationItem) {
      this.animationItem.play();
    }
  }

  pause(): void {
    if (this.animationItem) {
      this.animationItem.pause();
    }
  }

  stop(): void {
    if (this.animationItem) {
      this.animationItem.stop();
    }
  }
}
