// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
    width: 100%;
    height: 100%;
    padding: 0px;
    background-color: #fff;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/iframe-doc/iframe-doc.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":["iframe {\n    width: 100%;\n    height: 100%;\n    padding: 0px;\n    background-color: #fff;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
