// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-search{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.search-block{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--text);
  border-radius: 40px;
  background-color: var(--screen-background);
}
.container-search input{
  width: 100%;
  border-radius: 40px 0px 0px 40px;
  border: none;
  border-right: 1px solid var(--text);
  background-color: var(--screen-background);
  color: var(--text);
  padding: 0 10px;
  font-weight: 400;
  height: 30px;
  font-size: 14px;
}

.container-search input::placeholder{
  color: var(--text);
}

.container-icon{
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.icon-search{
  transform: scale(0.6);
  fill: var(--text);
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/search-bar/search-bar.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,mBAAmB;EACnB,0CAA0C;AAC5C;AACA;EACE,WAAW;EACX,gCAAgC;EAChC,YAAY;EACZ,mCAAmC;EACnC,0CAA0C;EAC1C,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".container-search{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n}\n.search-block{\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid var(--text);\n  border-radius: 40px;\n  background-color: var(--screen-background);\n}\n.container-search input{\n  width: 100%;\n  border-radius: 40px 0px 0px 40px;\n  border: none;\n  border-right: 1px solid var(--text);\n  background-color: var(--screen-background);\n  color: var(--text);\n  padding: 0 10px;\n  font-weight: 400;\n  height: 30px;\n  font-size: 14px;\n}\n\n.container-search input::placeholder{\n  color: var(--text);\n}\n\n.container-icon{\n  height: 32px;\n  width: 32px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.icon-search{\n  transform: scale(0.6);\n  fill: var(--text);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
