import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'pro-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {
  @Input() interventionName;
  @Input() ind;
  @Output() onClickStep: EventEmitter<any> = new EventEmitter();

  public interventions: string[];
  public steps : number[] = []
  step;


  constructor() {

  }

  ngOnInit(){
    this.interventions = localStorage.getItem('ids').split(',');
    this.stepsOfInterventions()
  }

  stepsOfInterventions(){

    for(let i = 0; i < this.interventions.length; i++){
      if(i == 0){
         this.step = this.interventions.length;
         this.steps.push(this.step);
      }else{
        this.step = this.interventions.length - i;

        this.steps.push(this.step);
      }
    }

  }

  onClickStepHandler(i){
    this.interventionName = null
    if(this.steps.length > 1){
      this.onClickStep.emit(i);
    }
  }

  onClickNext(i){
    this.interventionName = null
    if(i != this.steps.length -1){
      this.onClickStep.emit(i+1);
    }
  }

  onClickBack(i){
    this.interventionName = null
    if(i != 0){
      this.onClickStep.emit(i-1);
    }
  }
}

