import {
  Component,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { RevestimentoService } from "src/app/revestimento/revestimento.service";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
@Component({
  selector: "pro-proforma-navbar",
  templateUrl: "./proforma-navbar.component.html",
  styleUrls: ["./proforma-navbar.component.css"],
})
export class ProformaNavbarComponent implements AfterViewInit {
  constructor(
    private revestimentoService: RevestimentoService,
    private location: Location,
    private router: Router,
  ) { }
  @Input() showBack = null;
  @Input() showCart = false;
  @Input() showBotaoFiltro = false;
  @Input() showFiltroInspiracao = false;
  @Input() showFiltroMaterial = false;
  @Input() widthScreen: number = window.innerWidth;

  @Output() onClickBack = new EventEmitter<void>();
  @Output() onClickCart = new EventEmitter<void>();
  @Output() onClickBotaoFiltro = new EventEmitter<void>();

  leftMenuStatus: 'openModalMenu' | 'closeModalMenu' | '' = '';
  subscritions = new Subscription();
  menuModalStatus: 'openModalChange' | 'closeModalChange' | '' = '';

  isShowModalMenu: boolean = false;
  mouseOnModalEmail: boolean = false;

  preventClickPropagation(event: Event) {
    event.stopPropagation();
  }

  clickBackHandler() {
    this.onClickBack.emit();

    if(this.widthScreen < 768){
      if (this.leftMenuStatus === "openModalMenu") {
        this.menuLeftClick();
      }
    }

  }

  handleCart() {
    this.onClickCart.emit();
  }

  ngAfterViewInit() { }

  menuLeftClick() {
    if(this.widthScreen < 768){
      console.log("mobile",this.widthScreen)
      this.leftMenuStatus === "openModalMenu" ? this.leftMenuStatus = "closeModalMenu" : this.leftMenuStatus = "openModalMenu";
    }
    this.showBack = !this.showBack;
  }

  // Modal desktop
  openCloseModalMenu(){
    this.mouseOnModalEmail = false;
    this.isShowModalMenu = !this.isShowModalMenu;
  }

  isMouseOnModalEmail(){
    this.mouseOnModalEmail = true;
  }

  openCloseModalEmail() {
    this.menuModalStatus === "openModalChange" ? this.menuModalStatus = "closeModalChange" : this.menuModalStatus = "openModalChange";
    // this.menuLeftClick();
  }

  clickBotaoFiltroHandler() {
    this.isShowModalMenu = false;
    this.onClickBotaoFiltro.emit();
  }

  isEmail(): boolean {
    let serchfind = false;

    const regexp = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    serchfind = regexp.test(this.email);
    return serchfind;
  }

  email;

  async goToNext() {
    localStorage.setItem("emailLead", this.email);
    this.openCloseModalEmail();
    this.menuLeftClick();
    this.router.navigate(["leadResume"], {
      queryParams: {
        email: this.email,
      }
    });

    // this.revestimentoService.getLead(this.email).subscribe(
    //   (res) => {
    //     localStorage.setItem("emailLead", this.email);
    //     this.resultReforms = res['result']

    //     this.router.navigate(["leadResume", { lead: res["result"] }]);
    //   },
    //   (er) => {
    //     console.log("er", er);
    //     alert("Usuario não encontrado");
    //   }
    // );

  }
}
