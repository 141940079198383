import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "pro-proforma-footer",
  templateUrl: "./proforma-footer.component.html",
  styleUrls: ["./proforma-footer.component.css"],
})
export class ProformaFooterComponent {
  @Input() showBotaoFiltro = false;
  @Input() showFiltroInspiracao = false;
  @Input() showFiltroMaterial = false;
  @Input() showPularInspiracao = false;
  @Input() showQueroParede = false;
  @Input() showQueroPiso = false;
  @Input() showBotaoAvancar = false;
  @Input() showTotalObra = false;
  @Input() showTotalObraProduto = false;
  @Input() showSalvarLista = false;
  @Input() precoObra: null | number = null;
  @Input() precoObraProduto: null | number = null;
  @Input() leftAlign = false;

  @Input() disableQueroParede = false;
  @Input() disableQueroPiso = false;
  @Input() disableAcancar = false;

  @Output() onClickBotaoFiltro = new EventEmitter<void>();
  @Output() onClickPularInspiracao = new EventEmitter<void>();
  @Output() onClickQueroParede = new EventEmitter<void>();
  @Output() onClickQueroPiso = new EventEmitter<void>();
  @Output() onClickBotaoAvancar = new EventEmitter<void>();
  @Output() onClickMostrarPreco = new EventEmitter<void>();
  @Output() onClickSalvarLista = new EventEmitter<void>();
  constructor() {}

  clickBotaoFiltroHandler() {
    this.onClickBotaoFiltro.emit();
  }

  clickPularInspiracaoHandler() {
    this.onClickPularInspiracao.emit();
  }

  clickQueroParedeHandler() {
    this.onClickQueroParede.emit();
  }

  clickQueroPisoHandler() {
    this.onClickQueroPiso.emit();
  }

  clickBotaoAvancarHandler() {
    this.onClickBotaoAvancar.emit();
  }

  clickMostrarPrecoHandler() {
    this.onClickMostrarPreco.emit();
  }

  clickSalvarListaHandler() {
    this.onClickSalvarLista.emit();
  }
}
