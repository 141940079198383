import { Injectable } from '@angular/core';
import { WhiteLabelService } from '../whiteLabel/white-label.service';
import { IWhitelabel } from '../../shared/models/i-whitelabel';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { IStoreData } from 'src/app/shared/models/i-store-data';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private darkMode: boolean;
  private storeDataSubject = new BehaviorSubject<IStoreData>({
    urlLogo: '/assets/img/navbar/pro-reforma-branco.svg', 
    storeName: 'Pró-Reforma' 
  });

  constructor(private whitelabelService: WhiteLabelService) { }

  getFixedColors(isWhiteLabel: boolean, isDarkMode: boolean) {
    const defaultFixedColors = {
      filter: isDarkMode ? "#FFFFFF" : "#5D5757", 
      text: isDarkMode ? "#FFFFFF" : "#000000", 
      acceptSelection: "#2CA900", 
      formBackground: isDarkMode ? "#55504E" : "#E8E8E8", 
      screenBackground: isDarkMode ? "#151414" : "#FFFFFF", 
      review: isDarkMode ? "#FFD902" : "#FFE485"
    }

    const whitelabelFixedColors = {
      filter: isDarkMode ? "#C0D496" : "#5D5757",
      text: isDarkMode ? "#FFC38D" : "#000000",
      acceptSelection: "#2CA900",
      formBackground: isDarkMode ? "#406402" : "#E8E8E8",
      screenBackground: isDarkMode ? "#151414" : "#FFFFFF",
      review: isDarkMode ? "#FFD902" : "#FFE485"
    }
    
    return isWhiteLabel ? whitelabelFixedColors : defaultFixedColors;
  }

  getVariableColors(whitelabel: IWhitelabel | null, isWhiteLabel: boolean, isDarkMode: boolean) {
    const defaultTheme = {
      primaryBackground: "#D95536",
      primaryFont: "#FFFFFF",
      secondaryFont: isDarkMode ? "#E89987" : "#D95536",
      terciaryFont: "#D95536",
      primaryButtonDisabled: "#F1BFB4",
    };

    const whitelabelTheme = {
      primaryBackground: isDarkMode ? whitelabel?.darkPrimaryBackground : whitelabel?.primaryBackground,
      primaryFont: isDarkMode ? whitelabel?.darkPrimaryFont : whitelabel?.primaryFont,
      secondaryFont: isDarkMode ? whitelabel?.darkSecondaryFont : whitelabel?.secondaryFont,
      terciaryFont: isDarkMode ? whitelabel?.darkTerciaryFont : whitelabel?.terciaryFont,
      primaryButtonDisabled: isDarkMode ? whitelabel?.darkPrimaryButtonDisabled : whitelabel?.primaryButtonDisabled,
    }
  
    return isWhiteLabel ? whitelabelTheme : defaultTheme;
  }  

  getStoreInfo(whitelabel: IWhitelabel | null, isWhiteLabel: boolean): IStoreData {
    const defaultStoreData = { 
      urlLogo: '/assets/img/navbar/pro-reforma-branco.svg', 
      storeName: 'Pró-Reforma' 
    };

    const whitelabelStoreData = {
      urlLogo: whitelabel?.urlBrandLogo ?? '/assets/img/navbar/pro-reforma-branco.svg',
      storeName: whitelabel?.brandDomain ?? 'Pró-Reforma'
    };

    return isWhiteLabel ? whitelabelStoreData : defaultStoreData;
  }

  getTheme(url: string, isDarkMode: boolean): Observable<any> {
    return this.whitelabelService.getWhitelabelByDomain(url).pipe(
      map((response) => {
        const whitelabel = response.result;

        return this.createTheme(whitelabel, true, isDarkMode);
      }),
      catchError((err) => {
        if(err instanceof HttpErrorResponse && err.status !== 404) {
          console.error('Erro ao buscar o whitelabel:', err);
        }
        
        return of(this.createTheme(null, false, isDarkMode));
      })
    )
  }

  loadTheme(url: string, isDarkMode: boolean = false): void {
    this.darkMode = isDarkMode;
    this.getTheme(url, isDarkMode).subscribe((theme) => {
      const root = document.documentElement;
      
      //informações da loja
      this.storeDataSubject.next({urlLogo: theme.urlLogo, storeName: theme.storeName});

      // cores variaveis
      root.style.setProperty('--primary-background', theme.primaryBackground);
      root.style.setProperty('--primary-font', theme.primaryFont);
      root.style.setProperty('--secondary-font', theme.secondaryFont);
      root.style.setProperty('--terciary-font', theme.terciaryFont);
      root.style.setProperty('--primary-button-disabled', theme.primaryButtonDisabled);
      // cores fixas
      root.style.setProperty('--filter', theme.filter);
      root.style.setProperty('--text', theme.text);
      root.style.setProperty('--accept-selection', theme.acceptSelection);
      root.style.setProperty('--form-background', theme.formBackground);
      root.style.setProperty('--screen-background', theme.screenBackground);
      root.style.setProperty('--review', theme.review);
    });
  }

  getStoreData(): Observable<IStoreData>{
    return this.storeDataSubject.asObservable();
  }

  isDarkMode(): boolean {
    return this.darkMode;
  }

  createTheme(whitelabel: IWhitelabel | null, isWhiteLabel: boolean, isDarkMode: boolean) {
    const storeInfo = this.getStoreInfo(whitelabel, isWhiteLabel);
    const fixedColors = this.getFixedColors(isWhiteLabel, isDarkMode);
    const variableColors = this.getVariableColors(whitelabel, isWhiteLabel, isDarkMode);

    return { ...storeInfo, ...variableColors, ...fixedColors };
  }
}
