import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EcoCostService } from 'src/app/services/eco-cost/eco-cost.service';

@Component({
  selector: 'pro-eco-cost-toggle',
  templateUrl: './eco-cost-toggle.component.html',
  styleUrls: ["./eco-cost-toggle.component.css"]
})
export class EcoCostToggleComponent implements OnInit{
  @Input() isFinancialCost: boolean;
  
  constructor(private ecoCostService: EcoCostService) {}

  ngOnInit(): void {
    this.ecoCostService.isFinancialCost$.subscribe((isFinancialCost) => {
      this.isFinancialCost = isFinancialCost;
    });
  }
  
  toggleValue() {
    this.ecoCostService.toggleValue();
  }
}
