//dev
// const public_ip = process.env.public_ip || 'http://localhost:80/api'
const public_ip = process.env.public_ip || "https://api.pro-reforma.net/api";
// const public_ip = process.env.API_URL || 'http://13.67.229.50:3000/api'
// const public_ip = process.env.API_URL || 'http://13.67.229.50:3000/api'
// const public_ip = process.env.public_ip || 'https://api.pro-reforma.net/api'
// const public_ip = process.env.API_URL || 'http://localhost:3001/api'

export const environment = {
  apiUrlBase: public_ip,
  idContraPisoRegular: "d941f7a6-5dc2-4b70-8215-86da54ff5e1a",
  idEmboco: "d5d906a0-50b5-4d12-911e-b50807b6772e",
  production: false,
  hmr: false,
  dev: true
};
