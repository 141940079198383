import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeadSelecionadoService {

  leadSelected = new BehaviorSubject({"lead": "Sem Leads Selecionados"});

  constructor() { }

  selectLead(valor){
    this.leadSelected.next(valor);
    this.leadSelected.subscribe();
  }

  getSelectedLead(){
    return this.leadSelected.value;
  }
}
