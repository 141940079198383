import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { RevestimentoService } from "src/app/revestimento/revestimento.service";
import { SharedService } from "src/app/shared/shared.service";

@Component({
  selector: "pro-avaliacao",
  templateUrl: "./avaliacao.component.html",
  styleUrls: ['./avaliacao.component.css']
})
export class AvaliacaoComponent implements OnInit {
  constructor(private router: Router, private revestimentoService: RevestimentoService, private ratingService: SharedService) {
    this.ratingService.setSelectedStars(0);
  }
  ratingMessage: string = '';

  ngOnInit() { }

  sendRating() {
    if (this.ratingMessage !== '' || this.ratingService.getSelectedStars() !== 0) {
      let email = localStorage.getItem('emailLead')
      this.revestimentoService.sendRating(email, this.ratingMessage, this.ratingService.getSelectedStars()).subscribe(
        res => { },
        er => {
          alert("Erro ao enviar avaliação!");
        });
    }

  }

  goToNewReform() {
    this.ratingService.setPermission('');
    this.router.navigate(["/home"]);
    this.sendRating();
  }

  goToMyReforms() {
    const email = localStorage.getItem('emailUser');
    this.ratingService.setPermission('');
    this.router.navigate(["leadResume"],{
        queryParams:{
            email:email,
        }
    });
    this.sendRating();
  }

}
