import { Component, Input, TemplateRef, ContentChild, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'pro-grid-layout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './grid-layout.component.html',
  styleUrl: `./grid-layout.component.css`
})
export class GridLayoutComponent implements OnInit{
  @Input() gridContent : any[];
  @Input() cols : number;
  @Input() biggerGap : boolean;

  columns: number[] = []

  @ContentChild(TemplateRef, {static: false}) any;
  @Input() itemTemplate: TemplateRef<any>;

  ngOnInit(){
    for(let i = 0; i < this.cols; i++){
      this.columns.push(i);
      console.log(this.columns);
    }
  }
}
