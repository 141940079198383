import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { RevestimentoService } from 'src/app/revestimento/revestimento.service';
import { SharedService } from '../shared.service';

@Component({
  selector: 'pro-my-resume',
  templateUrl: './resumo-lista.component.html',
  styleUrl:"./resumo-lista.component.css"
})
export class ResumoListaComponent {
  @Input() selecteds;
  @Input() filters;
  @Input() showModalResumoLista: boolean = false;
  @Output() closeResumoLista = new EventEmitter();
  @Output() proOnSelected: EventEmitter<any> = new EventEmitter();
  @Output() backIntervention: EventEmitter<any> = new EventEmitter();

  public selected = [];
  public interventions = [];
  public interventionsOrder = [];
  public interventionsAndProducts;
  public totalPriceProducts = 0;
  public totalPriceProductsServices = 0;
  public totalPriceServices = 0;
  public save = false;
  public imgUrl = '';
  public typeCoating = '';
  public questions = [];
  public actualRoute;
  listName: string;
  listNameTemp: string;
  listNameInput: string;
  public minhaReformaObject;
  private ids;
  public leadDate : string;
  public lead;
  filtrosExistentes : string[] = [];
  filtrosFormatados : string;
  preexistence: any;
  material: any;
  loading: false;
  leadId = localStorage.getItem("leadId");

  constructor(private revestimentoService: RevestimentoService, private globals: Globals, private route: Router, private authService: SharedService) { }

  openCloseMyResume(){
    this.showModalResumoLista = !this.showModalResumoLista
    this.closeResumoLista.emit()
  }

  async ngOnInit() {

    this.actualRoute = this.route.url;


    this.calculateResumeProductsPrice();
    this.groupCategoriesByInterventions();

    this.getAllLeadData();
    this.getDetalhamentos();
    this.getSelectedFilters();
    this.getPreexistence();
    this.getMaterial();
  }

  getPreexistence() {
    let typeCoating = this.revestimentoService.getParamnsSelected('typeCoating');
    this.revestimentoService.getPreexistence(typeCoating).
    subscribe((res) => {
      let preexistenceId = this.revestimentoService.getParamnsSelected('PreexistenceID');
      this.preexistence = res.result.find((pre) => (pre.id === preexistenceId));

    })
  }

  getMaterial() {
    let materialId = this.revestimentoService.getParamnsSelected('MaterialID');
    this.revestimentoService.getMaterialById(materialId).
    subscribe((res) => {
      this.material = res.result;

    })
  }

  preventClickPropagation(event: Event) {
    event.stopPropagation();
  }

  onBackScreen(index) {
    // this.globals.listProd = !this.globals.listProd;
    // this.showModalResumoLista = false;
    this.backIntervention.emit(index)
  }


  calculateResumeProductsPrice() {
    for(const productCategory of this.selecteds) {
        if (productCategory.packagedProduct.products.isService) {
            this.totalPriceServices += productCategory.packagedProduct.storeSelected.vlrConsumo;
        } else {
            this.totalPriceProducts += productCategory.packagedProduct.storeSelected.vlrConsumo;
        }

        this.totalPriceProductsServices += productCategory.packagedProduct.storeSelected.vlrConsumo;
    }
  }

  groupCategoriesByInterventions() {
    const names = new Set(this.selecteds.map(c => c.interventionName))
    var interventionsLength = 0;

    for (const name of names) {
        this.interventions.push({
            name: name,
            categories: this.selecteds.filter(c => c.interventionName === name)
            })
            interventionsLength = this.interventions.length;
    }

    for(var i = 0; i <= this.interventions.length; i++){
      this.interventionsOrder.push(interventionsLength);
      interventionsLength = interventionsLength - 1;
    }

    this.setNameFatherForProductsComplementary(this.interventions);
  }

  setNameFatherForProductsComplementary(interventions){
    for(let intervention of interventions){
      let fatherName;
      let fatherId;
      for(let category of intervention.categories){
        if(!category.isComplementary){
          fatherName = (category.productCategoryName).toLowerCase();
          fatherId = category.packagedProduct.productId
        } else{
          if(category.isComplementary){
            if(category.father == fatherId){
              category.fatherName = fatherName
            }
          }
        }
      }
    }
  }

  comprarOuContratar(isService: boolean){
    if(isService == true){
      return "contratar";
    }else{
      return "comprar";
    }
  }

  initializeReformName(reformName?: string){
    if(reformName && reformName !== 'lista_salva_automatica'){
        this.listNameInput = reformName;
      }
      else if(!reformName){
        this.minhaReformaObject.listName = 'lista_salva_automatica';
      }
  }

  getLeadData(lead){
    this.minhaReformaObject = lead;
    this.initializeReformName(this.minhaReformaObject?.listName);
  }

  getAllLeadData(){

    let emailUser = localStorage.getItem("emailUser")

    this.revestimentoService.getLead(emailUser).subscribe(res => {
      for(let lead of res.result){
        if(lead.id === this.leadId){
          console.log(res.result, lead);
          this.getLeadData(lead);
          break;
        }
      }
    })

  }

  getSelectedFilters(){
    this.filtrosFormatados = this.filters.toLowerCase();
  }

  getDetalhamentos() {
    this.revestimentoService.getQuestions(this.revestimentoService.getParamnsSelected("ids")).subscribe(suc => {
        let questionAnswer = JSON.parse(this.revestimentoService.getParamnsSelected("questionAnswer"));
        let questionsSugesteds = suc.result;
        questionAnswer.forEach(question => {
            let qe = questionsSugesteds.find(el => el.id === question.question);
            if(qe) {
                let res = qe.answers.find(el => el.id === question.answer);
                this.questions.push({question: qe.query, answer: res.name})
            }
        });
    })

  }

  onOpenMenu() {
    let resumeProducts = localStorage.getItem('resumeProducts');
    let emailUser = localStorage.getItem('emailUser');
    let questionAnswer = localStorage.getItem('questionAnswer');

    let resumeProductsJson = JSON.parse(resumeProducts);
    let questionAnswerJson = JSON.parse(questionAnswer);

    resumeProductsJson.push(questionAnswerJson);

    let resumeProductsNew = JSON.stringify(resumeProductsJson);


    this.revestimentoService.updateLeadByEmail(emailUser, this.listNameInput, resumeProductsNew, this.leadId).subscribe(suc => {
      let listComplete = localStorage.getItem('listComplete');

      if (listComplete != "false") {
        this.globals.listProd = false;
        this.globals.resumoReforma = false;
        this.globals.especification = false;
        this.globals.prodCat = [];
        this.authService.setPermission('evaluation');
        this.route.navigate(["avaliacao/"]);
      }
      else {
        localStorage.setItem('listComplete', "");
        this.globals.listProd = false;
      }
    },
      error => {
        console.log("error", error);
      })
  }

  openStoreUrl(linkStore) {
    if (linkStore.match("https://")) {
      window.open(linkStore, '_blank');
    }
    else if (linkStore.match("http://")) {
      window.open(linkStore, '_blank');
    }
    else {
      window.open("https://" + linkStore, '_blank');
    }
  }

  onSelected(interv, category) {
    this.selected.forEach(element => {
      if (element.intervention == interv.id) {
        element.category = category.id;
      }
    });

  }

  showStores(cat) {
    // console.log("categ?", cat);
    cat.backMyList = true;
    this.proOnSelected.emit(cat);
  }
}
