// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg-modal-global h3 {
    font-size: 16px;
}

.bg-modal-global .close-button {
    width: 22px;
    height: auto;
    color: var(--screen-background);
    position: absolute;
    top: 7px;
    right: 10px;
    cursor: pointer;
}

.box-modal-base {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    background: var(--screen-background);
    border-top-left-radius: 20px;
    width: 100%;
    cursor: default;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s, visibility 0.5s;
}

.box-modal-base .header-title {
    margin-top: 5px;
    margin-bottom: 5px;
}

.modal-bg-header {
    background-color: var(--primary-font);
    border-top-left-radius: 20px;
}

.header-title {
padding: 20px 10px 5px 10px;
}

.header-title span {
    margin-bottom: 5px;
    font-size: 20px;
}

.content-modal{
    height: 100%;
    overflow: auto;
}

.content-modal div{
    margin:0 15px 30px 15px;
}

.content-modal div strong{
    color: #000000;
    font-weight: 500 !important;
    font-size: 16px;
}

.content-modal div span{
    font-size: 14px;
}

@media (max-width: 767px) {
    .close-button {
        height: auto !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/base-modal/base-modal.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,+BAA+B;IAC/B,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;IAChB,oCAAoC;IACpC,4BAA4B;IAC5B,WAAW;IACX,eAAe;IACf,gCAAgC;IAChC,yCAAyC;AAC7C;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,qCAAqC;IACrC,4BAA4B;AAChC;;AAEA;AACA,2BAA2B;AAC3B;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,2BAA2B;IAC3B,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".bg-modal-global h3 {\n    font-size: 16px;\n}\n\n.bg-modal-global .close-button {\n    width: 22px;\n    height: auto;\n    color: var(--screen-background);\n    position: absolute;\n    top: 7px;\n    right: 10px;\n    cursor: pointer;\n}\n\n.box-modal-base {\n    display: flex;\n    flex-direction: column;\n    max-width: 480px;\n    background: var(--screen-background);\n    border-top-left-radius: 20px;\n    width: 100%;\n    cursor: default;\n    transform: translate(-50%, -50%);\n    transition: opacity 0.5s, visibility 0.5s;\n}\n\n.box-modal-base .header-title {\n    margin-top: 5px;\n    margin-bottom: 5px;\n}\n\n.modal-bg-header {\n    background-color: var(--primary-font);\n    border-top-left-radius: 20px;\n}\n\n.header-title {\npadding: 20px 10px 5px 10px;\n}\n\n.header-title span {\n    margin-bottom: 5px;\n    font-size: 20px;\n}\n\n.content-modal{\n    height: 100%;\n    overflow: auto;\n}\n\n.content-modal div{\n    margin:0 15px 30px 15px;\n}\n\n.content-modal div strong{\n    color: #000000;\n    font-weight: 500 !important;\n    font-size: 16px;\n}\n\n.content-modal div span{\n    font-size: 14px;\n}\n\n@media (max-width: 767px) {\n    .close-button {\n        height: auto !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
