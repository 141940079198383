// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-base{
    display: flex;
    flex-direction: row;
}

.column-base{
    display: flex;
    flex-direction: column;
    margin: 0 12.5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/grid-layout/grid-layout.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".grid-base{\n    display: flex;\n    flex-direction: row;\n}\n\n.column-base{\n    display: flex;\n    flex-direction: column;\n    margin: 0 12.5px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
