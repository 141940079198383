import { Component, OnInit, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { RevestimentoService } from 'src/app/revestimento/revestimento.service';
import { Globals } from 'src/app/globals';
import { Router } from "@angular/router";
import { SharedService } from '../shared.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'pro-my-list',
  templateUrl: './my-list.component.html',
  styleUrls: ['./my-list.component.css']
})
export class MyListComponent implements OnInit, AfterViewInit {

  @Input() selecteds;
  @Input() filters;
  @Output() proOnSelected: EventEmitter<any> = new EventEmitter();
  @Output() backIntervention: EventEmitter<any> = new EventEmitter();

  public selected = [];
  public interventions = [];
  public interventionsOrder = [];
  public interventionsAndProducts;
  public totalPriceProducts = 0;
  public totalPriceProductsServices = 0;
  public totalPriceServices = 0;
  public save = false;
  public imgUrl = '';
  public typeCoating = '';
  public questions = [];
  // public afterSave = false;
  public actualRoute;
  listName: string;
  listNameTemp: string;
  listNameInput: string;
  public minhaReformaObject;
  private ids;
  public leadDate : string;
  public lead;
  filtrosFormatados : string;
  widthScreen = window.innerWidth;

  constructor(private revestimentoService: RevestimentoService, private globals: Globals, private route: Router, private authService: SharedService) { }

  async ngOnInit() {
    this.actualRoute = this.route.url;
    // console.log("routeActive: ", this.route.url)
    // console.log("selceted", this.selecteds);
    // this.selected = this.selected.filter(el => {return el != null});
    this.calculateResumeProductsPrice();
    this.groupCategoriesByInterventions();
    await this.saveListAutomatic();

    document.scrollingElement.scrollTop;

    this.getAllLeadData();
    this.getDetalhamentos();
    this.getSelectedFilters();

  }

  ngAfterViewInit() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.widthScreen = window.innerWidth;
  }

  calculateResumeProductsPrice() {
    for(const productCategory of this.selecteds) {
        if (productCategory.packagedProduct.products.isService) {
            this.totalPriceServices += productCategory.packagedProduct.storeSelected.vlrConsumo;
        } else {
            this.totalPriceProducts += productCategory.packagedProduct.storeSelected.vlrConsumo;
        }

        this.totalPriceProductsServices += productCategory.packagedProduct.storeSelected.vlrConsumo;
    }
  }

  groupCategoriesByInterventions() {
    const names = new Set(this.selecteds.map(c => c.interventionName))
    var interventionsLength = 0;

    for (const name of names) {
        this.interventions.push({
            name: name,
            categories: this.selecteds.filter(c => c.interventionName === name)
            })
            interventionsLength = this.interventions.length;
    }
    console.log(this.interventions);


    for(var i = 0; i <= this.interventions.length; i++){
      this.interventionsOrder.push(interventionsLength);
      interventionsLength = interventionsLength - 1;
    }

    this.setNameFatherForProductsComplementary(this.interventions);

  }

  setNameFatherForProductsComplementary(interventions){
    for(let intervention of interventions){
      let fatherName;
      let fatherId;
      for(let category of intervention.categories){
        if(!category.isComplementary){
          fatherName = (category.productCategoryName).toLowerCase();
          fatherId = category.packagedProduct.productId
        } else{
          if(category.isComplementary){
            if(category.father == fatherId){
              category.fatherName = fatherName
            }
          }
        }
      }
    }
  }


  comprarOuContratar(isService: boolean){
    if(isService == true){
      return "contratar";
    }else{
      return "comprar";
    }
  }

  async saveListAutomatic(){
    let resumeProducts = localStorage.getItem('resumeProducts');
    let emailUser = localStorage.getItem('emailUser');
    let questionAnswer = localStorage.getItem('questionAnswer');
    let listName = localStorage.getItem('listName');

    let resumeProductsJson = JSON.parse(resumeProducts);
    let questionAnswerJson = JSON.parse(questionAnswer);

    // console.log(resumeProductsJson.result);


    resumeProductsJson.push(questionAnswerJson);

    let resumeProductsNew = JSON.stringify(resumeProductsJson);

    try {
      let res = await firstValueFrom(this.revestimentoService.updateLeadByEmail(emailUser, listName ? listName : "lista_salva_automatica", resumeProductsNew));
      localStorage.setItem('emailLead', res.result.email);
    }
    catch (error) {
      console.log("error", error);
    }
  }

  getLeadData(lead){
    this.minhaReformaObject = lead;
    this.initializeReformName(this.minhaReformaObject?.listName);
  }

  initializeReformName(reformName?: string){
    if(reformName && reformName !== 'lista_salva_automatica'){
        this.listNameInput = reformName;
      }
  }

  getAllLeadData(){

      let leadId = localStorage.getItem("leadId");

      let emailUser = localStorage.getItem("emailUser")

      this.revestimentoService.getLead(emailUser).subscribe(res => {
        for(let lead of res.result){
          if(lead.id === leadId){
            this.getLeadData(lead);
            break;
          }
        }
      })

    }



  getSelectedFilters(){
    this.filtrosFormatados = this.filters.toLowerCase();
  }

  getDetalhamentos() {
    this.revestimentoService.getQuestions(this.revestimentoService.getParamnsSelected("ids")).subscribe(suc => {
        // console.log('questions',suc);
        let questionAnswer = JSON.parse(this.revestimentoService.getParamnsSelected("questionAnswer"));

        // console.log('qA',questionAnswer);

        let questionsSugesteds = suc.result;
        questionAnswer.forEach(question => {
            // console.log('question', question);

            let qe = questionsSugesteds.find(el => el.id === question.question);
            // console.log('qe', qe);
            if(qe) {
                let res = qe.answers.find(el => el.id === question.answer);
                this.questions.push({question: qe.query, answer: res.name})
            }

        });
        // console.log('perguntas e respostas',this.questions);

    })

  }

  onOpenMenu() {
    let resumeProducts = localStorage.getItem('resumeProducts');
    let emailUser = localStorage.getItem('emailUser');
    let questionAnswer = localStorage.getItem('questionAnswer');

    let resumeProductsJson = JSON.parse(resumeProducts);
    let questionAnswerJson = JSON.parse(questionAnswer);

    resumeProductsJson.push(questionAnswerJson);

    let resumeProductsNew = JSON.stringify(resumeProductsJson);


    this.revestimentoService.updateLeadByEmail(emailUser, this.listNameInput, resumeProductsNew).subscribe(suc => {
      let listComplete = localStorage.getItem('listComplete');
      // localStorage.setItem('lead', JSON.stringify(suc.result));

      if (listComplete != "false") {
        // console.log("Lead Salvo!");
        this.globals.listProd = false;
        // this.globals.product = false;
        this.globals.resumoReforma = false;
        this.globals.especification = false;
        this.globals.prodCat = [];

        // localStorage.clear();
        this.authService.setPermission('evaluation');
        this.route.navigate(["avaliacao/"]);
      }
      else {
        localStorage.setItem('listComplete', "");
        this.globals.listProd = false;
      }

    },
      error => {
        console.log("error", error);
      })
  }

  onBackScreen(index) {
    this.globals.listProd = !this.globals.listProd;
    this.backIntervention.emit(index)
  }

  onNavigate() {
    this.save = !this.save;
  }

  preventClickPropagation(event: Event) {
    event.stopPropagation();
  }

  openStoreUrl(linkStore) {
    if (linkStore.match("https://")) {
      window.open(linkStore, '_blank');
    }
    else if (linkStore.match("http://")) {
      window.open(linkStore, '_blank');
    }
    else {
      window.open("https://" + linkStore, '_blank');
    }
  }

  onDisplaySave() {
    this.save = !this.save;
  }

  onDisplayAfterSave() {
    // this.save = !this.save;
    // this.afterSave = !this.afterSave;
    this.onOpenMenu();
  }

  onSelected(interv, category) {
    // console.log("intervae", interv);
    // console.log("categ", category);
    this.selected.forEach(element => {
      // console.log("teste", element);
      if (element.intervention == interv.id) {
        element.category = category.id;
      }
    });
    // console.log("selecteda", this.selected);

  }

  showStores(cat) {
    // console.log("categ?", cat);
    cat.backMyList = true;
    this.proOnSelected.emit(cat);
  }

}
