import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class WhiteLabelService {
  private domainSubject = new BehaviorSubject<string>(window.location.href);
  private isWhitelabelSelectSubject = new BehaviorSubject<boolean>(false);
  private baseUrl: string = environment.apiUrlBase;
  private urlLogo: string;
  private storeName: string;
  private isDarkThemed: boolean = false;

  domain$ = this.domainSubject.asObservable();
  isWhitelabelSelect$ = this.isWhitelabelSelectSubject.asObservable();

  constructor(private http: HttpClient) { }

  getWhitelabelByDomain(band_domain: string): Observable<any> {
    return this.http.get(`${this.baseUrl}/whitelabels/domain`, {
      headers: {
        brand_domain: band_domain
      }
    })
  }

  getAllWhitelabels(): Observable<any> {
    return this.http.get(`${this.baseUrl}/admin/whitelabels`);
  }

  updateDomain(domain: string) {
    this.domainSubject.next(domain);
  }

  toggleWhitelabelSelect() {
    this.isWhitelabelSelectSubject.next(!this.isWhitelabelSelectSubject.value);
  }

  getLabelColor(url: string){
      if (url.includes('20.226.53.175:8000')){
        return {
          "urlLogo":"https://upload.wikimedia.org/wikipedia/commons/thumb/d/d4/Leroy_Merlin.svg/176px-Leroy_Merlin.svg.png",
          "storeName":"Leroy Merlin",
          "primaryBackground": "#84BCDA",
          "primaryFont": "#042D98",
          "secondaryFont": "#C137C4",
          "terciaryFont": "#F1561C",
          "primaryButtonDisabled": "#ECC30B",
          "primaryBackgroundDark": "#84BCDA",
          "primaryFontDark": "#042D98",
          "secondaryFontDark": "#C137C4",
          "terciaryFontDark": "#F1561C",
          "primaryButtonDisabledDark": "#ECC30B",
          "filterDark": "#C0D496",
          "textDark": "#FFC38D",
          "acceptSelectionDark": "#2CA900",
          "formBackgroundDark": "#406402",
          "screenBackgroundDark": "#151414",
          "ratingDark": "#FFD902",
        }
    }
  }

  loadTheme(url: string) : void {
    if(url.includes('20.226.53.175:8000')){
      let infoStore = this.getLabelColor(url);
      const root = document.documentElement;

      //informações da loja
      this.urlLogo = infoStore?.urlLogo;
      this.storeName = infoStore?.storeName;

      //cores padrão do tema
      if(!this.isDarkThemed){
        root.style.setProperty('--primary-background', infoStore.primaryBackground);
        root.style.setProperty('--primary-font', infoStore.primaryFont);
        root.style.setProperty('--secondary-font', infoStore.secondaryFont);
        root.style.setProperty('--terciary-font', infoStore.terciaryFont);
        root.style.setProperty('--primary-button-disabled', infoStore.primaryButtonDisabled);
      }else{
        root.style.setProperty('--primary-background', infoStore.primaryBackgroundDark);
        root.style.setProperty('--primary-font', infoStore.primaryFontDark);
        root.style.setProperty('--secondary-font', infoStore.secondaryFontDark);
        root.style.setProperty('--terciary-font', infoStore.terciaryFontDark);
        root.style.setProperty('--primary-button-disabled', infoStore.primaryButtonDisabledDark);
        root.style.setProperty('--filter', infoStore.filterDark);
        root.style.setProperty('--text', infoStore.textDark);
        root.style.setProperty('--accept-selection', infoStore.acceptSelectionDark);
        root.style.setProperty('--form-background', infoStore.formBackgroundDark);
        root.style.setProperty('--screen-background', infoStore.screenBackgroundDark);
        root.style.setProperty('--rating', infoStore.ratingDark);
      }
    }
  }

  getLogoStore(): string {
    if (this.urlLogo) {
      return this.urlLogo;
    }
    return "/assets/img/navbar/pro-reforma-branco.svg";
  }

  getStoreName(): string {
    return this.storeName;
  }

  setDarkTheme(isDark: boolean){
    this.isDarkThemed = isDark
  }
}
