import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'pro-criterios',
  templateUrl: './criterios.component.html',
  styleUrls: ['./criterios.component.css']
})
export class CriteriosComponent implements OnInit {

  constructor(private router: Router, private location: Location) { }

  url = this.router.routerState.snapshot.url;
  code = "1Wz382LjOM5tLgJ4pKkHWVBXKSWTlP0BW";

  ngOnInit() {
  }

  goBack() {
    if (this.url.includes('/#/criterios')) {
      this.router.navigate(["home"]);
    } else {
      this.location.back();
    }

  }

}
