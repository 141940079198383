// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lista-filtro span {
    font-size: 14px;
    text-transform: lowercase;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-left: 1px;
    color: var(--text);
}

.mat-checkbox-inner-container{
  height: 16px !important;
  width: 16px !important;
  flex-shrink: 0 !important;
}

.mat-checkbox-layout{
  margin-bottom: 0px !important;
}

.span-filter{
  font-size: 10px !important;
  text-transform: uppercase !important;
  text-decoration: underline !important;
}

@media (min-width: 768px) {

    .lista-filtro span {
        text-transform: lowercase;
    }

}

.lista-filtro-pad {
    padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/filter-navbar/filter-navbar.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;EACE,uBAAuB;EACvB,sBAAsB;EACtB,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,0BAA0B;EAC1B,oCAAoC;EACpC,qCAAqC;AACvC;;AAEA;;IAEI;QACI,yBAAyB;IAC7B;;AAEJ;;AAEA;IACI,UAAU;AACd","sourcesContent":[".lista-filtro span {\n    font-size: 14px;\n    text-transform: lowercase;\n    font-style: normal;\n    font-weight: 400;\n    line-height: normal;\n    padding-left: 1px;\n    color: var(--text);\n}\n\n.mat-checkbox-inner-container{\n  height: 16px !important;\n  width: 16px !important;\n  flex-shrink: 0 !important;\n}\n\n.mat-checkbox-layout{\n  margin-bottom: 0px !important;\n}\n\n.span-filter{\n  font-size: 10px !important;\n  text-transform: uppercase !important;\n  text-decoration: underline !important;\n}\n\n@media (min-width: 768px) {\n\n    .lista-filtro span {\n        text-transform: lowercase;\n    }\n\n}\n\n.lista-filtro-pad {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
