import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// Material icon
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

// configs de array svg
import { iconsArray, customIcon } from "./shared/configs/custom-icons";
import { ThemeService } from "./services/theme/theme.service";
import { WhiteLabelService } from "./services/whiteLabel/white-label.service";

@Component({
  selector: "pro-root",
  templateUrl: "./app.component.html",
  styleUrls: [],
})
export class AppComponent implements OnInit {
  constructor(
    public router: Router,
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    private themeService: ThemeService,
    private whitelabelService: WhiteLabelService
  ) {
    console.log("starting.");

    this.initializeIcons();
  }

  isOverLay = false;
  title = "proreforma";

  ngOnInit() {
    this.whitelabelService.domain$.subscribe(domain => {
    const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.themeService.loadTheme(domain, darkMode);
    console.log(domain)
    })
  }

  initializeIcons() {
    iconsArray.forEach((icon: customIcon) => {
      this.iconRegistry.addSvgIcon(
        icon.iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.iconPath)
      );
    });
  }

  setOverlay(event) {
    this.isOverLay = event;
  }

  closeMenu() {
    if (this.isOverLay) {
      this.isOverLay = false;
    }
  }
}
