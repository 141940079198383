// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-stars {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.star-icon {
    width: 37px;
    height: 38px;
    padding-right: 5px;
}

@media (min-width:768px) {
    .star-icon {
        width: 41.5px;
        height: 38.82px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/rating-stars/rating-stars.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;QACI,aAAa;QACb,eAAe;IACnB;AACJ","sourcesContent":[".rating-stars {\n    display: flex;\n    justify-content: center;\n    width: 100%;\n    padding-top: 30px;\n    padding-bottom: 30px;\n}\n\n.star-icon {\n    width: 37px;\n    height: 38px;\n    padding-right: 5px;\n}\n\n@media (min-width:768px) {\n    .star-icon {\n        width: 41.5px;\n        height: 38.82px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
