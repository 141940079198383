import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { MatExpansionPanel } from "@angular/material/expansion";

@Component({
  selector: "pro-expansion-panel",
  templateUrl: "./expansion-panel.component.html",
  styleUrls: ["./expansion-panel.component.css"],
})
export class ExpansionPanelComponent implements AfterViewInit {
  @ViewChild(MatExpansionPanel, { static: false })
  expansionPanel: MatExpansionPanel;

  constructor(private el: ElementRef) {}

  isPanelOpen: boolean = false;
  @Input() customExpandedHeight: string | null = null;
  @Input() customCollapsedHeight: string | null = null;
  @Input() selectedOption: any;
  @Input() radioOptions: any[] = [];
  @Input() tempSelectedOption: any;
  @Input() marginTop: number; // margin top influencia no posicionamento do elemento e cálculo de sua altura máxima
  @Output() optionSelected = new EventEmitter<any>();
  @Output() onPanelChange = new EventEmitter<any>();

  @ViewChild('content', { static: false }) content!: ElementRef;

  ngAfterViewInit(): void {
    this.setMaxHeight(this.el.nativeElement.getBoundingClientRect().top - this.marginTop);
  }

  onPanelOpenedChange(opened: boolean): void {
    this.isPanelOpen = opened;

    if (!opened && this.tempSelectedOption) {
      this.selectedOption = this.tempSelectedOption;
    }
  }

  checkPanelChange() {
    this.onPanelChange.emit();
    this.isPanelOpen = !this.isPanelOpen;
  }

  onSelectOption(option: any): void {
    this.optionSelected.emit(option);

    // Fechando após seleção
    this.expansionPanel.close();
    this.checkPanelChange();
  }

  getOptionLabel(optionValue: string): string {
    const selectedOption = this.radioOptions.find(
      (option) => option.value === optionValue
    );
    return selectedOption ? selectedOption.label : "";
  }

  setMaxHeight(yPos: number) {
    const maximumHeight = window.innerHeight - yPos - 40;
    const contentElement = this.content.nativeElement as HTMLElement;
    contentElement.style.maxHeight = `${maximumHeight}px`;
  }
}
