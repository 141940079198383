import { BrowserModule } from "@angular/platform-browser";
import { NgModule, LOCALE_ID } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedModule } from "./shared/shared.module";
import { HomeComponent } from "./home/home.component";
import { TokenInterceptorService } from "./shared/token-interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { Globals } from "./globals";
import { LeadResumeComponent } from "./lead-resume/lead-resume.component";
import { registerLocaleData } from "@angular/common";
import br from "@angular/common/locales/pt";
import { LazyimgsDirective } from "./lazyimgs.directive";
import { SobreComponent } from "./sobre/sobre.component";
import { TermosComponent } from "./termos/termos.component";
import { CriteriosComponent } from "./criterios/criterios.component";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { AvaliacaoComponent } from "./contato/avaliacao/avaliacao.component";
import { FormsModule } from "@angular/forms";
import {SearchBarComponent} from "./shared/search-bar/search-bar.component";
import { GridLayoutComponent } from "./shared/grid-layout/grid-layout.component";
import { BaseModalComponent } from "src/app/shared/base-modal/base-modal.component";

registerLocaleData(br);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SobreComponent,
    TermosComponent,
    CriteriosComponent,
    LeadResumeComponent,
    AvaliacaoComponent,
    LazyimgsDirective,
  ],
    imports: [
        MatBottomSheetModule,
        MatDialogModule,
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatIconModule,
        MatExpansionModule,
        MatChipsModule,
        FormsModule,
        SearchBarComponent,
        GridLayoutComponent,
        BaseModalComponent
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'pt' },
    Globals,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
