import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'pro-iframe-doc',
  templateUrl: './iframe-doc.component.html',
  styleUrls: ['./iframe-doc.component.css']
})
export class IframeDocComponent implements OnInit {
  @Input() code: string;
  link: SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    // Sanitiza a URL usando DomSanitizer
    this.link = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/document/d/${this.code}/preview`);
  }
}
