import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'pro-termos',
  templateUrl: './termos.component.html',
  styleUrls: ['./termos.component.css']
})
export class TermosComponent implements OnInit {

  constructor(private router: Router, private location: Location) { }

  url = this.router.routerState.snapshot.url;
  code = "1KgBugdOAJZZiQJ2YsbaN4FzgurbDd7pQ"

  ngOnInit() {
  }

  goBack() {
    if (this.url.includes('/#/termos')) {
      this.router.navigate(["home"]);
    } else {
      this.location.back();
    }

  }

}
