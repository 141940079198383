import { Component, HostListener, OnInit, } from '@angular/core';
import { RevestimentoService } from "../revestimento/revestimento.service";
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../globals';
import { Location, ViewportScroller } from '@angular/common';

@Component({
  selector: 'pro-lead-resume',
  templateUrl: './lead-resume.component.html',
  styleUrls: ['./lead-resume.component.css']
})
export class LeadResumeComponent implements OnInit {

  constructor(
    private revestimentoService: RevestimentoService,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    private router: Router,
    private globals: Globals,

    private location: Location
  ) { }

  somaTotal: number = 0;
  leads: any[];
  leadsVisible: any[];
  public selected = [];
  url = this.router.routerState.snapshot.url;
  idAnchor: string
  leadsVisibleLeft = [];
  leadsVisibleRight = [];
  widthScreen;
  gridCols;

  ngOnInit() {

    this.widthScreen = window.innerWidth;
    this.onResize();
    let email = localStorage.getItem('emailLead')
    let idUrl = ''
    if (this.activatedRoute.snapshot.paramMap.get('email') != null) {
      email = this.activatedRoute.snapshot.paramMap.get('email')
      localStorage.setItem('emailLead', email)
    }

    this.activatedRoute.fragment.subscribe(fragment => {
      if (fragment) {
        idUrl = fragment.split('?')[0];
      }
    });

    this.revestimentoService.getLead(email).subscribe(res => {
      this.leads = res.result;
      this.leadsVisible = res.result
      this.goFragment(idUrl)

    }, er => {
      console.log("er", er);
      // alert('Usuario não encontrado')
      alert("Erro ao buscar reformas!")
    })
  }

  goFragment(idUrl: string) {
    if (idUrl) {
      this.router.navigateByUrl(`leadResume#${idUrl}`)
    }
  }


  goBack() {
    if (this.url.includes('leadResume')) {
      this.router.navigate(["home"]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.widthScreen = window.innerWidth;

    if (this.widthScreen < 1280) {
      this.gridCols = 1;
    } else if (this.widthScreen >= 1280) {
      this.gridCols = 2;
    }
  }

  goToIntervention(id, environmentId, imgUrl, appearanceId, styleId, areaM2, nomeUser, emailUser, stateSelected, colorId, standardId, preexistenceId, materialId, actionId, idActionPossible) {

    window.localStorage.setItem("leadId", id);
    window.localStorage.setItem("EnvironmentId", environmentId);
    window.localStorage.setItem("imgurl", imgUrl);
    window.localStorage.setItem("AppearanceId", appearanceId);
    window.localStorage.setItem("StyleId", styleId);
    window.localStorage.setItem("AreaM2", areaM2);
    window.localStorage.setItem("nomeUser", nomeUser);
    window.localStorage.setItem("emailUser", emailUser);
    window.localStorage.setItem("stateSelected", stateSelected);
    window.localStorage.setItem('colorEnvId', colorId); //Necessário para buscar os produtos corretamente de acordo com o ambiente selecionado
    window.localStorage.setItem('standardEnvId', standardId); //Necessário para buscar os produtos corretamente de acordo com o ambiente selecionado

    window.localStorage.setItem("PreexistenceID", preexistenceId);
    window.localStorage.setItem("MaterialID", materialId);
    window.localStorage.setItem("PossibleActionID", actionId);
    window.localStorage.setItem("idActionPossible", idActionPossible);

    this.globals.listProd = false;

    this.router.navigate(["/reforma/detalhamento/"]);
  }

  goToMyList(id, environmentId, imgUrl, appearanceId, styleId, areaM2, nomeUser, emailUser, stateSelected, colorId, standardId, preexistenceId, materialId, actionId, idActionPossible, resumeProducts, interventionListIds, listName) {

    window.localStorage.setItem("leadId", id);
    window.localStorage.setItem("EnvironmentId", environmentId);
    window.localStorage.setItem("imgurl", imgUrl);
    window.localStorage.setItem("AppearanceId", appearanceId);
    window.localStorage.setItem("StyleId", styleId);
    window.localStorage.setItem("AreaM2", areaM2);
    window.localStorage.setItem("nomeUser", nomeUser);
    window.localStorage.setItem("emailUser", emailUser);
    window.localStorage.setItem("stateSelected", stateSelected);
    window.localStorage.setItem('colorEnvId', colorId); //Necessário para buscar os produtos corretamente de acordo com o ambiente selecionado
    window.localStorage.setItem('standardEnvId', standardId); //Necessário para buscar os produtos corretamente de acordo com o ambiente selecionado

    window.localStorage.setItem("PreexistenceID", preexistenceId);
    window.localStorage.setItem("MaterialID", materialId);
    window.localStorage.setItem("PossibleActionID", actionId);
    window.localStorage.setItem("idActionPossible", idActionPossible);
    window.localStorage.setItem("resumeProducts", JSON.stringify(resumeProducts.slice(0, -1)));
    window.localStorage.setItem("flagLeadReturn", "yes");
    window.localStorage.setItem("listName", listName);

    try {
      if (resumeProducts.slice(-1)[0][0]) {
        // console.log("resumeProducts.questionAnswer: ", resumeProducts.slice(-1)[0])
        let questionAnswer = JSON.stringify(resumeProducts.slice(-1)[0]);
        window.localStorage.setItem("questionAnswer", questionAnswer);
      }
    } catch (er) {
      console.log("er", er);
    }

    window.localStorage.setItem("ids", interventionListIds);

    this.selected = resumeProducts.slice(0, -1);
    // console.log("selected: ", this.selected)

    this.globals.prodCat = [];;
    this.selected.forEach(element => {
      if (element && element.packagedProduct && Object.keys(element.packagedProduct).length > 0) {
        this.globals.prodCat.push([element.packagedProduct.products.id, element.productCategoryId]);
      }

    });
    this.globals.sel = this.selected.filter(el => {
      return el != null;
    })
    // console.log("this.sel", this.globals.sel);

    // this.globals.sel = this.selected.sl
    this.globals.listProd = true;

    this.router.navigate(["/reforma/intervencao-one/"]);
  }

  getTotalAPartirDe(lead) {
    let totalAPartirDe = 0.0;
    if (lead.resumeProducts == null) return 0.0;
    for (let resumeProduct of lead.resumeProducts) {
      if (resumeProduct == null) continue;
      if (resumeProduct.packagedProduct == null) continue;
      if (resumeProduct.packagedProduct.minVlrConsumo == null) continue;
      totalAPartirDe += resumeProduct.packagedProduct.storeSelected.vlrConsumo
    }

    return totalAPartirDe;
  }

  textoFiltro = ''
  filter(textoFiltro) {
    this.leadsVisible = []
    this.leads.map(s => {
      if (!s.listName) {
        if ('lista sem nome'.search(textoFiltro.toLowerCase()) >= 0)
          this.leadsVisible.push(s)
      }
      else
        if (s.listName.toLowerCase().search(textoFiltro.toLowerCase()) >= 0)
          this.leadsVisible.push(s)
    })

    this.populateVisible()
  }

  populateVisible() {
    this.leadsVisibleLeft = []
    this.leadsVisibleRight = []
    for (let i = 0; i < this.leadsVisible.length; i++) {
      if (i % 2 == 0) {
        this.leadsVisibleLeft.push(this.leadsVisible[i])
      } else {
        this.leadsVisibleRight.push(this.leadsVisible[i])
      }
    }
  }


  order = false
  modificacao() {

    this.order = !this.order
    if (this.order)
      this.leadsVisible.sort(function (a, b) {

        // ordena decrescente baseado no timestamp
        return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
      });

    if (!this.order)
      this.leadsVisible.sort(function (a, b) {

        // ordena crescente baseado no timestamp
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      });

    this.populateVisible()
  }


}
