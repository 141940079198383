export type customIcon = {
  iconName: string;
  iconPath: string;
};

export const iconsArray: customIcon[] = [
  {
    iconName: "menu",
    iconPath: "../../../assets/img/navbar/menu-hamburguer.svg",
  },
  {
    iconName: "back-button",
    iconPath: "../../../assets/img/navbar/back-button.svg",
  },
  {
    iconName: "cart-button",
    iconPath: "../../../assets/img/navbar/cart-button.svg",
  },
  {
    iconName: "cart-modal",
    iconPath: "../../../assets/img/navbar/cart-modal.svg",
  },
  {
    iconName: "funnel-primary",
    iconPath: "../../../assets/img/funnel-primary.svg",
  },
  {
    iconName: "funnel",
    iconPath: "../../../assets/img/footer/funnel.svg",
  },
  {
    iconName: "funnel-filled",
    iconPath: "../../../assets/img/navbar/funnel-filled.svg",
  },
  {
    iconName: "bolt",
    iconPath: "../../../assets/img/footer/bolt.svg",
  },
  {
    iconName: "bolt-filled",
    iconPath: "../../../assets/img/navbar/bolt-filled.svg",
  },
  {
    iconName: "info-circle",
    iconPath: "../../../assets/svg/revestimento/info-circle.svg",
  },
  {
    iconName: "cross-circle",
    iconPath: "../../../assets/svg/revestimento/cross-circle.svg",
  },
  {
    iconName: "cross-circle-black",
    iconPath: "../../../assets/svg/revestimento/cross-circle-black.svg",
  },
  {
    iconName: "question-circle",
    iconPath: '../../../assets/svg/revestimento/question-circle.svg'
  },
  {
    iconName: "pro-reforma-sobre",
    iconPath: '../../../assets/img/menu/pro-reforma-sobre.svg'
  },
  {
    iconName: "clip-path-group",
    iconPath: '../../../assets/img/menu/clip-path-group.svg'
  },
  {
    iconName: "bolt-menu",
    iconPath: '../../../assets/img/menu/bolt-menu.svg'
  },
  {
    iconName: "bolt-menu-filled",
    iconPath: '../../../assets/img/menu/bolt-menu-filled.svg'
  },
  {
    iconName: "rating-star",
    iconPath: '../../../assets/img/avaliacao/rating-star.svg'
  },
  {
    iconName: "cart-stroked-icon",
    iconPath: '../../../assets/img/minha-reforma/cart-stroked-icon.svg'
  },
  {
    iconName: "super-poder-icon",
    iconPath: '../../../assets/img/minha-reforma/super-poder-icon.svg'
  },
  {
    iconName: "info-circle-stroked",
    iconPath: '../../../assets/img/produto/info-circle-stroked.svg'
  },
  {
    iconName: "box-new",
    iconPath: '../../../assets/svg/box-icon.svg'
  },
  {
    iconName: "search",
    iconPath: '../../../assets/svg/search.svg'
  },
  {
    iconName: "next",
    iconPath: '../../../assets/svg/modal-menu/next.svg'
  },
  {
    iconName: "arrow-next",
    iconPath: '../../../assets/img/stepper/arrow-next.svg'
  },
  {
    iconName: "arrow-back",
    iconPath: '../../../assets/img/stepper/arrow-back.svg'
  },
  {
    iconName: "whitelabel-icon",
    iconPath: '../../../assets/svg/wl-icon.svg'
  },
  {
    iconName: "arrow-forward",
    iconPath: '../../../assets/svg/arrow-forward.svg'
  },
  {
    iconName: "co2-icon",
    iconPath: '../../../assets/svg/co2-icon.svg'
  },
  {
    iconName: "wallet-icon",
    iconPath: '../../../assets/svg/wallet-icon.svg'
  }

];
