import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EcoCostService {
  private isFinancialCostSubject = new BehaviorSubject<boolean>(true);

  isFinancialCost$ = this.isFinancialCostSubject.asObservable();
  
  constructor() { }

  toggleValue(): void {
    this.isFinancialCostSubject.next(!this.isFinancialCostSubject.value);
  }
}
