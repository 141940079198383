import {Component, EventEmitter, Output,Input} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import { CommonModule } from '@angular/common';


@Component({
  selector: 'pro-search-bar',
  standalone: true,
  imports: [
    MatIconModule,
    FormsModule,
    CommonModule
  ],
  templateUrl: './search-bar.component.html',
  styleUrl: `./search-bar.component.css`
})
export class SearchBarComponent {
  @Input() placeholder = '';
  @Input() searchOnTyping = false;
  @Input() searchOnClick = true;
  @Input() collapsed = false;
  @Output() clickSearch = new EventEmitter();
  @Output() onChangeCollapsed = new EventEmitter()

  inputValue: string;

  emissor(){
    this.clickSearch.emit(this.inputValue !== undefined ? this.inputValue : "");
  }

  changeCollapsed() {
    this.collapsed = !this.collapsed;
    this.onChangeCollapsed.emit();
  }
}
