import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'pro-base-modal',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './base-modal.component.html',
  styleUrl: `./base-modal.component.css`
})
export class BaseModalComponent {

  @Input() title;
  @Input() icon;
  @Input() isShowModal;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter()

  close(){
    this.closeModal.emit()
  }

  preventClickPropagation(event: Event) {
    event.stopPropagation();
  }
}