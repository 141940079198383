import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router: Router, private authService: SharedService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    const requiredPermission = route.data.permission;

    if (this.authService.getPermission(requiredPermission)) {
      return true;
    } else {
      return false;
    }
  }
}
