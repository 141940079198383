export class EnvironmentsFilter {
  colorId = "";
  standardId = "";
  styleId = "";
  colorWallId = "";
  standardWallId = "";
  constructor(listColor?, listStand?, listStile?, listColorWall?, listStandWall?) {
    this.colorId = listColor;
    this.standardId = listStand;
    this.styleId = listStile;
    this.colorWallId = listColorWall;
    this.standardWallId = listStandWall;
  }
}
