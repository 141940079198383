import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { EcoCostService } from "src/app/services/eco-cost/eco-cost.service";
import { ThemeService } from "src/app/services/theme/theme.service";

@Component({
  selector: "pro-proforma-footer",
  templateUrl: "./proforma-footer.component.html",
  styleUrls: ["./proforma-footer.component.css"],
})
export class ProformaFooterComponent implements OnInit{
  @Input() showBotaoFiltro = false;
  @Input() showFiltroInspiracao = false;
  @Input() showFiltroMaterial = false;
  @Input() showPularInspiracao = false;
  @Input() showQueroParede = false;
  @Input() showQueroPiso = false;
  @Input() showBotaoAvancar = false;
  @Input() showTotalObra = false;
  @Input() showTotalObraProduto = false;
  @Input() showSalvarLista = false;
  @Input() precoObra: null | number = null;
  @Input() precoObraProduto: null | number = null;
  @Input() leftAlign = false;

  @Input() disableQueroParede = false;
  @Input() disableQueroPiso = false;
  @Input() disableAcancar = false;
  @Input() disableVerTotalObra = false;

  @Output() onClickBotaoFiltro = new EventEmitter<void>();
  @Output() onClickPularInspiracao = new EventEmitter<void>();
  @Output() onClickQueroParede = new EventEmitter<void>();
  @Output() onClickQueroPiso = new EventEmitter<void>();
  @Output() onClickBotaoAvancar = new EventEmitter<void>();
  @Output() onClickMostrarPreco = new EventEmitter<void>();
  @Output() onClickSalvarLista = new EventEmitter<void>();

  isDarkMode: boolean;
  isReformaPath: boolean;
  isTextoAvancar: boolean;
  isFinancialCost: boolean;
  isRevestimentoPath: boolean;

  constructor(private themeService: ThemeService, private router: Router, private ecoCostService: EcoCostService) {}

  ngOnInit(): void {
    this.isDarkMode = this.themeService.isDarkMode();
    this.isReformaPath = this.isCurrentPath('/reforma');
    this.isRevestimentoPath = this.isCurrentPath('/revestimento') && !this.isCurrentPath('/revestimento/piso-nao-selecionado');
    this.isTextoAvancar = this.isCurrentPath('/revestimento') || this.isCurrentPath('/revestimento/piso-nao-selecionado');
    this.ecoCostService.isFinancialCost$.subscribe(isFinancialCost => this.isFinancialCost = isFinancialCost);
  }

  isCurrentPath(path: string): boolean {
    return this.router.url.includes(path);
  }

  toggleEcoCost(): void {
    this.ecoCostService.toggleValue();
  }

  clickBotaoFiltroHandler() {
    this.onClickBotaoFiltro.emit();
  }

  clickPularInspiracaoHandler() {
    this.onClickPularInspiracao.emit();
  }

  clickQueroParedeHandler() {
    this.onClickQueroParede.emit();
  }

  clickQueroPisoHandler() {
    this.onClickQueroPiso.emit();
  }

  clickBotaoAvancarHandler() {
    this.onClickBotaoAvancar.emit();
  }

  clickMostrarPrecoHandler() {
    this.onClickMostrarPreco.emit();
  }

  clickSalvarListaHandler() {
    this.onClickSalvarLista.emit();
  }
}
