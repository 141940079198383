// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inspiration-grid {
  /* display: -ms-flexbox; IE10 */
  display: flex;
  /* -ms-flex-wrap: wrap; IE10 */
  padding: 0 4px;
  justify-content: center;
  padding-bottom: 100px;
  max-width: 1810px;
  margin: auto;
}

.inspiration-link {
  position: relative;
  overflow: hidden;
}

.inspiration-link .exclusive-tag {
  position: absolute;
  right: 0;
  top: 8px;
  height: 15px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--primary-background);
  color: var(--primary-font);
  font-weight: 700;
  font-size: 11px;
  border-bottom-left-radius: 8px;
}

@media screen and (min-width: 768px) {
  .inspiration-link .exclusive-tag {
    height: 27px;
    width: 98px;
    font-size: 16px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/masory-grid/masory-grid.component.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,aAAa;EACb,8BAA8B;EAC9B,cAAc;EACd,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,2CAA2C;EAC3C,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;IACX,eAAe;EACjB;AACF","sourcesContent":[".inspiration-grid {\n  /* display: -ms-flexbox; IE10 */\n  display: flex;\n  /* -ms-flex-wrap: wrap; IE10 */\n  padding: 0 4px;\n  justify-content: center;\n  padding-bottom: 100px;\n  max-width: 1810px;\n  margin: auto;\n}\n\n.inspiration-link {\n  position: relative;\n  overflow: hidden;\n}\n\n.inspiration-link .exclusive-tag {\n  position: absolute;\n  right: 0;\n  top: 8px;\n  height: 15px;\n  width: 65px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  background-color: var(--primary-background);\n  color: var(--primary-font);\n  font-weight: 700;\n  font-size: 11px;\n  border-bottom-left-radius: 8px;\n}\n\n@media screen and (min-width: 768px) {\n  .inspiration-link .exclusive-tag {\n    height: 27px;\n    width: 98px;\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
