// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    text-align: center;
    margin-top: 20px;
}

.description {
    padding: 20px;
    max-width: 1350px;
    margin: 0 auto;
    text-align: justify;
}`, "",{"version":3,"sources":["webpack://./src/app/sobre/sobre.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".title {\n    text-align: center;\n    margin-top: 20px;\n}\n\n.description {\n    padding: 20px;\n    max-width: 1350px;\n    margin: 0 auto;\n    text-align: justify;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
