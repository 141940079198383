import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'pro-rating-stars',
  templateUrl: './rating-stars.component.html',
  styleUrls: ['./rating-stars.component.css']
})
export class RatingStarsComponent implements OnInit {

  constructor(private starService: SharedService) { }

  selectedRating = this.starService.getSelectedStars();
  hoverRating: number = 0;

  starsArray = Array(5).fill(0);

  selectStar(rating: number): void {
    this.selectedRating = rating;
    this.starService.setSelectedStars(rating);
  }

  resetHoverRating(): void {
    this.hoverRating = 0;
  }

  ngOnInit() {
  }

}
