import { Component, OnInit, Input, HostListener, DoCheck } from "@angular/core";
import { Router } from "@angular/router";
import { IEnviroment } from "src/app/revestimento/models/i-enviroment";

@Component({
  selector: "pro-masory-grid",
  templateUrl: "./masory-grid.component.html",
  styleUrls: ['./masory-grid.component.css']
})
export class MasoryGridComponent implements OnInit, DoCheck {
  numberCols = 2;
  constructor(private router: Router) { }
  ngDoCheck(): void {
    this.adjustListEnt(this.numberCols);
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;

    if (width < 768 && this.numberCols !== 2) {
      this.numberCols = 2
    } else if ((width >= 768 && width < 1200) && this.numberCols !== 4) {
      this.numberCols = 4;
    } else if (width >= 1200 && this.numberCols !== 7) {
      this.numberCols = 7;
    }
    this.adjustListEnt(this.numberCols);
  }



  @Input() listEnviroment: IEnviroment[];

  myOptions: any = {
    itemSelector: ".item",
    masonry: {
      columnWidth: ".grid-sizer",
      fitWidth: false
    }
  };

  adjustListEnt(number) {
    switch (number) {
      case 2:
        for (let i = 0; i < this.listEnviroment.length; i += 2) {
          for (let j = 0; j < 2; j++) {
            let item = this.listEnviroment[i+j];
            if (item)
              item['col'] = j;
          }
        }
        break;
      case 4:
        for (let i = 0; i < this.listEnviroment.length; i += 4) {
          for (let j = 0; j < 4; j++) {
            let item = this.listEnviroment[i+j];
            if (item)
              item['col'] = j;
          }
        }
        break;
      case 7:
        for (let i = 0; i < this.listEnviroment.length; i += 7) {
          for (let j = 0; j < 7; j++) {
            let item = this.listEnviroment[i+j];
            if (item)
              item['col'] = j;
          }
        }
    }
  }

  ngOnInit() {
    if (window.innerWidth < 768) {
      this.numberCols = 2
    } else if (window.innerWidth >= 768 && window.innerWidth < 1200) {
      this.numberCols = 4;
    } else {
      this.numberCols = 7;
    }
    this.adjustListEnt(this.numberCols);
    // console.log("this.lis", this.listEnviroment);
    window.localStorage.removeItem("imgurl");
  }

  goToPage(item: any) {
    if (item.id) {
      localStorage.setItem('appWall', item.appearanceWallId);
      localStorage.setItem('appFloor', item.appearanceId);
      this.router.navigate(["revestimento/piso-interna", item.id]);
      window.scrollTo(0, 0);
    }
    // localStorage.setItem('appWall', item.appearanceWallId);
    // localStorage.setItem('appFloor', item.appearanceId);
    // this.router.navigate(["revestimento/piso-interna", item.id]);
    // if (this.router.url.includes('/revestimento/piso-interna')) {
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 50);

    // }
  }
}
