import { IFilter } from "./../models/i-filter";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";

@Component({
  selector: "pro-filter-navbar",
  templateUrl: "./filter-navbar.component.html",
  styleUrls:["./filter-navbar.component.css"]
})
export class FilterNavbarComponent {
  subscritions = new Subscription();
  @Input() listFilters: IFilter[];
  @Input() title = "";
  @Output() proOnFilterSelected: EventEmitter<string[]> = new EventEmitter();
  @Input() clFil: boolean = false;

  @Input() internalFilterList = new Array<string>();
  selectAll = false;
  idItem: any;

  constructor(private router: Router) { }

  url = this.router.routerState.snapshot.url;

  private clearFilter(filter: boolean) {
    if (filter) {
      this.internalFilterList = new Array<string>();
    }
  }

  findIndexFilter(filter: IFilter) {
    const idx = this.internalFilterList.findIndex(c => c === filter.id);
    return idx;
  }

  addFilterById(filter: IFilter) {
    if (this.findIndexFilter(filter) < 0) {
      this.internalFilterList.push(filter.id);
    }
  }

  removeFilterById(filter: IFilter) {
    const idx = this.findIndexFilter(filter);
    if (idx >= 0) {
      this.internalFilterList.splice(idx, 1);
    }
  }

  fiterByFilter(filter: any, checked: boolean) {
    if (checked) {
      if(filter == 'all'){
        this.selectAll = true;
        this.internalFilterList = this.listFilters.map<string>(c => c.id);
        // console.log("internalFilterList", this.internalFilterList);
      } else{
        // console.log("filter", filter);
        // console.log("title", this.title);


        this.addFilterById(filter);
      }
    } else {
      if(filter == 'all'){
        this.selectAll = false;
        this.clearFilter(true);
      } else {
        this.selectAll = false;
        this.removeFilterById(filter);
      }
    }
    this.proOnFilterSelected.emit(this.internalFilterList);
  }

  selectAllFilters(filter: boolean) {
    if (filter) {
      this.selectAll = true;
      this.internalFilterList = this.listFilters.map<string>(c => c.id);
      console.log("internalFilterList", this.internalFilterList);

    } else {
      this.selectAll = false;
      this.clearFilter(true);
    }
    this.proOnFilterSelected.emit(this.internalFilterList);
  }

  validateItemForTypeWall(item: {name: string}){
    let type = localStorage.getItem('typeCoating');
    if(type !== 'parede') return true;
    if(item.name.toLowerCase().includes('comercial') || item.name.toLowerCase().includes('antiderrapante') ) return false;
    return true;
  }
}
