// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    text-align: center;
    margin-top: 20px;
}

.description {
    padding: 20px;
    max-width: 1350px;
    margin: 0 auto;
    text-align: justify;
}

iframe {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    background-color: #fff;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/criterios/criterios.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB","sourcesContent":[".title {\n    text-align: center;\n    margin-top: 20px;\n}\n\n.description {\n    padding: 20px;\n    max-width: 1350px;\n    margin: 0 auto;\n    text-align: justify;\n}\n\niframe {\n    width: 100%;\n    height: 100%;\n    padding-left: 20px;\n    padding-right: 20px;\n    background-color: #fff;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
