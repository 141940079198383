import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { SharedRoutingModule } from "./shared-routing.module";
import {
  ProformaNavbarComponent,
} from "./proforma-navbar/proforma-navbar.component";
import { MasoryGridComponent } from "./masory-grid/masory-grid.component";
import { FilterNavbarComponent } from "./filter-navbar/filter-navbar.component";
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox'

// material imports
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

import { MyListComponent } from "./my-list/my-list.component";
import { ProductScreenComponent } from "./product-screen/product-screen.component";
import { ProformaFooterComponent } from "./proforma-footer/proforma-footer.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { ExpansionPanelComponent } from "./expansion-panel/expansion-panel.component";
import { IframeDocComponent } from './iframe-doc/iframe-doc.component';
import { RatingStarsComponent } from './rating-stars/rating-stars.component';
import { StepperComponent } from "./stepper/stepper.component";
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { ProductDropdownComponent } from './product-dropdown/product-dropdown.component';
import { ResumoListaComponent } from "./resumo-lista/resumo-lista.component";
import {MatMenuModule} from '@angular/material/menu';
import { SearchBarComponent } from "./search-bar/search-bar.component";
import { GridLayoutComponent } from "../shared/grid-layout/grid-layout.component";
import { BaseModalComponent } from "src/app/shared/base-modal/base-modal.component";
import { LottieAnimationComponent } from "./lottie-animation/lottie-animation.component";
import { provideCacheableAnimationLoader, provideLottieOptions } from "ngx-lottie";
import player from 'lottie-web';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { EcoCostToggleComponent } from "./eco-cost-toggle/eco-cost-toggle.component";

@NgModule({
    providers: [
      provideLottieOptions({ player: () => player }),
      provideCacheableAnimationLoader(),
    ],
    declarations: [
        ProformaNavbarComponent,
        MasoryGridComponent,
        FilterNavbarComponent,
        // ProductScreenComponent,
        MyListComponent,
        ProformaFooterComponent,
        ExpansionPanelComponent,
        IframeDocComponent,
        RatingStarsComponent,
        StepperComponent,
        ProductDropdownComponent,
        ResumoListaComponent,
        EcoCostToggleComponent
    ],
    imports: [
        CommonModule,
        SharedRoutingModule,
        FormsModule,
        MatIconModule,
        MatSidenavModule,
        MatRadioModule,
        MatTooltipModule,
        MatButtonModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatStepperModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatMenuModule,
        SearchBarComponent,
        GridLayoutComponent,
        BaseModalComponent,
        LottieAnimationComponent,
        MatSlideToggleModule
    ],
    exports: [
        ProformaNavbarComponent,
        MasoryGridComponent,
        FilterNavbarComponent,
        MyListComponent,
        ProformaFooterComponent,
        ExpansionPanelComponent,
        IframeDocComponent,
        RatingStarsComponent,
        StepperComponent,
        ProductDropdownComponent,
        ResumoListaComponent,
        SearchBarComponent,
        GridLayoutComponent,
        BaseModalComponent,
        LottieAnimationComponent,
        EcoCostToggleComponent
    ]
})
export class SharedModule {}
